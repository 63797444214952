import Registry from 'core/ComponentRegistry';
import DataLayer from 'services/DataLayer';
import { applePay, isIOS, browserInfo } from 'core/shims/support';
import lazysizes from 'lazysizes';
import AOS from 'aos';
import 'lazysizes/plugins/native-loading/ls.native-loading';
import 'components/analytics/GTM'; // The GTM component must be loaded before any other
import 'core/ModalManager';
import { coreJsPolyfills } from 'core/shims/coreJsPolyfills';
import { mediaQuery } from 'toolbox/mediaQuery';
import { deepMerge } from 'toolbox/deepMerge';
import { Event } from 'services/EventEmitter';
import { on } from 'toolbox/event';
import { debounce } from 'toolbox/debounce';
import { throttle } from 'toolbox/throttle';

window.lora = window.lora || {};

const classNames = {
    modalActive: 'm-scroll-blocked', // Added to html element classes while the toaster is opened
    fullScreenAbsolute: 'h-layout-full-screen-absolute', // Helper for providing necessary css rules for full screen layout with absolute positioning
};
let scrollPosition = 0;

/**
 * Normalize Viewport Units across devices
 * Get the viewport height and multiple it by 1% to get a value for a vh unit.
 * Then set the value in the --vh custom property to the root of the document.
 * --vh custom property should be referenced in CSS instead of vh units.
 * Example: height: calc(var(--vh, 1vh) * 100);
 *
 * Context: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function normalizeViewportUnits() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/**
 * Patch history API to listen to pushState and replaceState events globally
 * This is useful for tracking state changes
 */
function patchHistory() {
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function pushStateMethod(...args) {
        // Call our custom onpushstate function if it exists
        if (typeof window.onpushstate === 'function') {
            const state = args[0];
            window.onpushstate({ state });
        }

        return originalPushState.apply(this, args);
    };

    window.history.replaceState = function replaceStateMethod(...args) {
        // Call our custom onreplacestate function if it exists
        if (typeof window.onreplacestate === 'function') {
            const state = args[0];
            window.onreplacestate({ state });
        }

        return originalReplaceState.apply(this, args);
    };
}

/**
 * Bind any external events
 */
function bindEvents() {
    patchHistory();

    // Add support for bg image lazyload
    document.addEventListener('lazybeforeunveil', (e) => {
        const bg = e.target.getAttribute('data-bg');

        if (bg) {
            e.target.style.backgroundImage = `url('${bg}')`;
        }
    });

    // iOS fix for the back-forward cache issue on cart page
    window.addEventListener('pageshow', (e) => {
        if (e.persisted) {
            const pageId = DataLayer.getData().page.id || {};
            if (pageId === 'cart') {
                window.location.reload();
            }
        }
    });

    Event.on('page.scroll.disabled', (isBlockScrollForAll = false) => {
        // Fix Android issue when the modal may be overlapped by browser panel
        // Save scroll position to restore after modal is closed
        if (mediaQuery.is('medium down') || isIOS()) {
            scrollPosition = document.documentElement.scrollTop;
            window.scrollTo(0, 0);
            document.documentElement.classList.add(classNames.modalActive);
        }

        if (isBlockScrollForAll) {
            document.documentElement.classList.add(classNames.modalActive);
        }
    }, this);

    Event.on('page.scroll.enabled', (options = {}) => {
        if (options.isUnblockScrollForAll) {
            document.documentElement.classList.remove(classNames.modalActive);
        }

        if (mediaQuery.is('medium down') || isIOS()) {
            document.documentElement.classList.remove(classNames.modalActive);
            if (options.isInstantScroll) {
                window.scroll({ top: scrollPosition, behavior: 'instant' });
            } else {
                window.scrollTo(0, scrollPosition);
            }
        }
    }, this);

    Event.on('full.screen.absolute.enabled', (options = {}) => {
        document.body.classList.add(classNames.fullScreenAbsolute);
        if (typeof options.onEnable === 'function') {
            setTimeout(() => {
                options.onEnable();
            }, 100);
        }
    }, this);

    Event.on('full.screen.absolute.disabled', () => {
        document.body.classList.remove(classNames.fullScreenAbsolute);
    }, this);

    on('resize', window, debounce(() => {
        normalizeViewportUnits();
        Event.emit('page.resized', true);
    }, 300));

    on('scroll', window, throttle(() => {
        Event.emit('page.scrolled', true);
    }, 300), {
        passive: true,
    });

    on('beforeunload', window, () => {
        if (mediaQuery.is('medium down') || isIOS()) {
            // enable page scroll to keep scroll position after page reload
            Event.emit('page.scroll.enabled', {
                isUnblockScrollForAll: true,
                isInstantScroll: true,
            });
        }
    });
}

/**
 * Enable debug mode if query string contains debug keyword
 */
function checkDebugMode() {
    const isDebug = /debug/.test(window.location.search);
    if (isDebug) {
        window.lora.debug = true;
    }
}

/**
 * If Apple Pay JS API is not available hide applePay components
 */
function checkBrowserSupport() {
    if (!applePay()) {
        document.body.classList.add('no-apple-pay');
    }
}

/**
 * Ready is called to bootstrap the app
 */
function ready() {
    bindEvents();
    checkBrowserSupport();
    normalizeViewportUnits();

    Registry.run();
    mediaQuery._init();
    lazysizes.init();
    AOS.init({
        startEvent: 'DOMContentLoaded',
        once: true, // whether animation should happen only once - while scrolling down
    });
}

/**
 * Add user-scalable viewport for IOS touch devices
 * Prevent zoom when focusing on form element on IOS
 */
function addScalableViewport() {
    if (!isIOS()) {
        return;
    }

    const viewport = document.querySelector('meta[name="viewport"]');
    const viewportContent = viewport.getAttribute('content');
    const regScalable = /(user-scalable=[\w]+)/i;

    if (viewportContent.match(regScalable)) {
        // Set user-scalable value with disabled state
        viewport.setAttribute('content', viewportContent.replace(regScalable, 'user-scalable=no'));
    } else {
        // Add user-scalable value with disabled state
        viewport.setAttribute('content', viewportContent.concat(', user-scalable=no'));
    }
}

/**
 * Initialize the images
 * FF && srcset fix for responsive images that have width/height attributes
 * See details here: https://jira.e-loreal.com/browse/NGLORA-18432
 */
function initImages() {
    const browser = browserInfo();
    const isFirefox = browser.name === 'Firefox';
    const images = document.querySelectorAll('img');

    images.forEach((image) => {
        const isPicture = image.parentNode.querySelector('source');
        const isSourceSet = image.hasAttribute('data-srcset');
        if ((isFirefox && isPicture) || isSourceSet) {
            image.removeAttribute('width');
            image.removeAttribute('height');
        }
    });
}

/**
 * App init
 */
function init() {
    checkDebugMode();
    initImages();
    addScalableViewport();
    DataLayer.initialize();

    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
        ready();
    } else {
        document.addEventListener('DOMContentLoaded', ready);
    }
}

Promise.all([
    coreJsPolyfills(),
]).then(() => {
    window.lora = deepMerge(window.lora, {
        version: '{{LORA_VERSION}}', // being replaced during build
        buildVersion: '{{BUILD_NUMBER}}', // being replaced during build
        mountedComponents: Registry.mountedComponents,
        getComponentsByName: Registry.getComponentsByName.bind(Registry),
        getComponent: Registry.getComponent.bind(Registry),
        toolkit: () => SystemJS.import('core/debug/Toolkit').then(() => {
            if (window.lora.debug) {
                console.log('Toolkit loaded');
            }
        }),
    });

    init();
});
